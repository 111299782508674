import React, { Component } from 'react';
import SubPageNavigationItem from './SubPageNavigationItem';
import { Row, Col } from 'react-bootstrap';
import './../../stylesheets/content.css'

class PageContent extends Component {
	render() {
		const pageContent = this.props.pageContent;
		const image = {...this.props.pageContent.image};
		//console.log(image);
		const imageStyle = {
					backgroundImage: 'url(/imgs/'+image.src+')',
					backgroundSize: image.zoom+'%',
					backgroundPosition:''+image.x+'% '+image.y+'%',
				};
		return (
			<div className='page-content'>
				{pageContent && pageContent.map((paragraph, index)=>(
					<Row id={paragraph.link} className='page-content-whole-paragraph'>
						<Col sm={3} className='page-content-image'>
							<SubPageNavigationItem item={paragraph}/>
						</Col>
						<Col sm={9} className='page-content-description'>
							{paragraph.content.map((singleLine, index) => {
									if(typeof(singleLine) === 'string')
									{
										return(<p key={'p'+index}>{singleLine}</p>)
									}
									else if(Array.isArray(singleLine) === true)
									{
										return(
										<ul key={'ul'+index}>
										{singleLine.map((listElement, idx)=>(
												<li key={'li'+idx}>{listElement}</li>
											))}
										</ul>)
									}
								})
							
							}
						</Col>
					</Row>
					))}
			</div>
		) 
	}
}

export default PageContent;