import React, { Component } from 'react';

import './../../stylesheets/content.css'

class SubPageNavigationItem extends Component {
	render() {
		const item={...this.props.item};
		const image = {...this.props.item.image};
		console.log(image);
		const imageStyle = {
						backgroundImage: 'url(/imgs/'+image.src+')',
						backgroundSize: image.zoom+'%',
						backgroundPosition:''+image.x+'% '+image.y+'%',
					};
		console.log(imageStyle);
		return (
			<div className='sub-page-navigation-item'>
				<a href={'#'+item.link}>
					<div 
						style={imageStyle}
						name={'img_'+item.link}
					>
					<p>{item.caption}</p>
					</div>
				</a>
			</div>
		) 
	}
}

export default SubPageNavigationItem;