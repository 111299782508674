import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class StairKind extends Component {
	  constructor () {
    super();
    this.state = {
      kinds: [
        {
          caption: 'Tre trapp',
          id: 'kind0',
          name: 'kind',
          type: 'radio',
        },
        {
          caption: 'Trapp på betongbase',
          id: 'kind1',
          name: 'kind',
          type: 'radio',
        },
      ],
    }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
	}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }
	render() {
		return (
        <Form.Group as={Row} className="justify-content-sm-center">
          {this.state.kinds.map((kind, index)=>(
            <Form.Label column sm={6} htmlFor={kind.id} key={index}>
	              <div className='radioButtonForm'>
	              <Form.Check id={kind.id} type={kind.type} name={kind.name} checked={this.props.kind===kind.id}  onChange={this.handleUserInput}/>
	              <p>{kind.caption}</p>
	              </div>
            </Form.Label>        
          ))}
          <Col sm={12}>
          	<Button ref={(el) => { this.nextButton = el; }} style={!this.props.kind ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
          </Col>
        </Form.Group>
		) 
	}
}

export default StairKind;