import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';


class NavItem extends Component {
	render() {
		const item=this.props.item;
		return (
			<Nav.Link id={item.name} onClick={this.props.handleViewChange} href={item.link} ><p className='menu-item'>{item.caption}</p></Nav.Link>
		) 
	}
}

export default NavItem;