import React, { Component } from "react";
import { Row, Col, Form, Container} from 'react-bootstrap';
import { postkode } from "./postkode";

class PostNrComponent extends Component {
  constructor () {
	  super();
	  this.state = {
      postnr: '',
      post: ''
    }
  }

  
  onChange = e => {
    const userInput = e.currentTarget.value;
    let stringPostNr = ''+userInput;
    //console.log(stringPostNr)
    let selectedCity = stringPostNr.length === 4 && postkode.filter((city) => city[0] === userInput);
    selectedCity = selectedCity && selectedCity[0] ? selectedCity[0][1] : '';
    //console.log(selectedCity);
    //odczytaj z state i zapisz do bazy danych
    this.setState({postnr: stringPostNr, post: selectedCity});
    let {saveValue} = this.props;
    saveValue('postkode', stringPostNr);
    saveValue('post', selectedCity);
  };



  render (){
      let {postnr, post} = this.state;

    return (
    <Container fluid>
      <Row>
        <Form.Label style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} className='text-center d-lg-block' column xs={12} lg={6}><h4>POST</h4></Form.Label>
        <Col lg={3} xs={6}>
        <Form.Control id='postnr' placeholder='Post nr' type='text' value={postnr} onChange={this.onChange} maxLength={4} required/>
        </Col>
        <Form.Label style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} className='text-center d-lg-block' column xs={6} lg={3}><h4>{post}</h4></Form.Label>

      </Row>
    </Container>
    );
  }
}

export default PostNrComponent;
