import React, { Component } from "react";
import { Row, Col, Form, Container} from 'react-bootstrap';

class PhoneComponent extends Component {
  constructor () {
	  super();
	  this.state = {
          mva: false,
    }

  }

  componentDidMount() {

  }

  handleChange = (e) => {
    const {value, name } = e.target,
    returnValue = this.props.saveValue;
    returnValue(name, value);
  }

  render (){
    const {value, record, setting} = {...this.props};
    return (
      <Container fluid>
        <Row>
        <Form.Label style={{color: this.props.error ? '#FF0000' : 'inherit', fontWeight: this.props.error ? 800 : 'initial'}} className='text-center d-lg-block' column xs={12} lg={6}><h4>{'TLF'}</h4></Form.Label>     
          <Col lg={6} xs={12}>
        <Form.Control type="tel" id="phone" name={record} pattern="[0-9]{8}" maxLength={8}
          placeholder={record} value={value} onChange={this.handleChange} required/>
        </Col>
        </Row>
    </Container>
    );
  }
}

export default PhoneComponent;
