import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import './../../../stylesheets/forms.css'

class Railings extends Component {
  constructor () {
    super();
    this.state = {
      railings: [
        {
          caption: 'Horisontale lister',
          img: 'railing_1.svg',
          id: 'railing0',
          name: 'railing',
          type: 'radio',
        },
        {
          caption: '„Horisontale (metal) spiler',
          img: 'railing_2.svg',
          id: 'railing1',
          name: 'railing',
          type: 'radio',
        },
        {
          caption: 'Glass',
          img: 'railing_3.svg',
          id: 'railing2',
          name: 'railing',
          type: 'radio',
        },
        {
          caption: 'Baluster',
          img: 'railing_4.svg',
          id: 'railing3',
          name: 'railing',
          type: 'radio',
        },
      ]
      }
    this.handleTextInput = this.handleTextInput.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }

  handleTextInput (event) {
  	const value = event.target.value,
  		name = event.target.name,
  		forwardObject = {[name]: value};
  	this.props.saveValues(forwardObject);
  	}
	render() {
		return (
			<div>
	      <Col>
	        <Form.Group as={Row} className="justify-content-sm-center">
	          {this.state.railings.map((railing, index)=>(
	            <Form.Label column sm={3} htmlFor={railing.id} key={index}>
	              <div className='radioButtonForm'>
	              <Form.Check id={railing.id} type={railing.type} name={railing.name} checked={this.props.railing===railing.id}  onClick={this.handleUserInput} />
	              {railing.img && <img src={'/form_icons/'+railing.img} />}
	              <p>{railing.caption}</p>
	              </div>
	            </Form.Label>        
	          ))}
	        </Form.Group> 
	        <Form.Group as={Row} controlId='extraRailing'>
	          <Form.Label column sm={2}>
	            REKKVERK I TRAPPEÅPNING
	          </Form.Label>
	          <Col sm={10}>
	            <Form.Control type='number' name='extraRailing' onChange={this.handleTextInput} value={this.props.extraRailing} placeholder={"Lengde i løpemeter"} />
	          </Col>
	      </Form.Group>
	    </Col>
	    <Col sm={12}>
	        <Button ref={(el) => { this.nextButton = el; }} style={!this.props.railing ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark' name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
	    </Col>
	  </div>
		) 
	}
}

export default Railings;