import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import './../../../stylesheets/forms.css'

class HoleType extends Component {
  constructor () {
    super();
    this.state = {
      holes: [
        {
          caption: 'Rektangel',
          img: 'hole_rectangEmpty.svg',
          id: 'hole0',
          name: 'hole',
          type: 'radio',
        },
        {
          caption: 'M',
          img: 'hole_mEmpty.svg',
          id: 'hole1',
          name: 'hole',
          type: 'radio',
        },
        {
          caption: 'L-venstre',
          img: 'hole_l_leftEmpty.svg',
          id: 'hole2',
          name: 'hole',
          type: 'radio',
        },
        {
          caption: 'L-høyre',
          img: 'hole_l_rightEmpty.svg',
          id: 'hole3',
          name: 'hole',
          type: 'radio',
        },
        {
          caption: 'Rund',
          img: 'hole_roundEmpty.svg',
          id: 'hole4',
          name: 'hole',
          type: 'radio',
        },
      ]
      }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }
	render() {
		return (
        <Form.Group as={Row} className="justify-content-sm-center">
          {this.state.holes.map((hole, index)=>(
            <Form.Label htmlFor={hole.id} column sm={4} key={index} >
              <div className='radioButtonForm'>
              <Form.Check id={hole.id} type={hole.type} name={hole.name} checked={this.props.hole===hole.id}  onClick={this.handleUserInput}/>
              {hole.img && <img src={'/form_icons/'+hole.img}/>}
              <p>{hole.caption}</p>
              </div>
            </Form.Label>        
          ))}
          <Col sm={12}>
          	<Button ref={(el) => { this.nextButton = el; }} style={!this.props.hole ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
          </Col>
        </Form.Group> 
		) 
	}
}

export default HoleType;