import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class ConfirmationMessageSend extends Component {
	  constructor () {
    super();
    this.state = {};
    this.closeView = this.closeView.bind(this);
  }

  closeView() {
			const object = {progress: 0, messageId: false};
			this.props.closeConfirmationView(object);
		}
	render() {
		const order = this.props.messageId;
		return (
			<div className='formSummary'>
				<Row>
					<Col sm={12}>
					<h1>BEKREFTELSE</h1>
					</Col>
					<Col sm={6}>
						
						<p>{'Skjema id: ' + this.props.messageId + ' sendt.'}</p>					
					</Col>
					<Col sm={6}>
						<p>Takk for at du kontakter oss!</p>   
					</Col>
          <Button style={{borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='ok' id='ok' onClick={this.closeView}>OK</Button>
				</Row>
			</div>
		) 
	}
}

export default ConfirmationMessageSend;