import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class KontaktSuggestion extends Component {
	  constructor (props) {
    super(props);
    this.state = {
    	mail: 'kontakt@laskowskibygg.no',
    	mobile: '+4796861689'
    };
  }
	render() {
		return (
			<div>
				<Row className='kontakt-info justify-content-md-center'>
					<Col sm={12}style={{textAlign:'center'}} >
					<h1>{this.props.need ? 'Trenger du '+ this.props.need +'? ' : ''}</h1><h1 id={this.props.form} name={this.props.referentName} onClick={this.props.handleViewChange} style={{cursor: 'pointer'}} >{this.props.message}</h1>
					</Col>
					<Col sm={12}lg={{offset:2, span:4}} style={{textAlign:'center'}}>
						<h2>{'Du kan kontakte oss på telefon fra mandag til fredag ​​9:00 - 18:00.'}</h2>
						<h3><a href={"tel:"+this.state.mobile}>{this.state.mobile}</a></h3>					
					</Col>
					<Col sm={12}lg={4} style={{textAlign:'center'}}>
						<h2>{'Skriv oss en e-post.'}</h2>
						<h3><a href={"mailto:"+this.props.mail}>{this.props.mail}</a></h3>   
					</Col>
				</Row>
			</div>
		) 
	}
}

export default KontaktSuggestion;