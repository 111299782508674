import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import './stylesheets/App.css';
import NavigationBar from './components/nav/NavigationBar';

import SocialPopup from './components/popups/SocialPopup';
import CookiesPopup from './components/popups/CookiesPopup';
import MainContentSection from './components/content/MainContentSection';

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
  } from "react-router-dom";
  


import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.js';
import 'jquery/dist/jquery.js';

import './stylesheets/App.css';
import './stylesheets/footer.css';
import GodJulPopup from './components/GodJul/GodJulPopup';
import SnowFlakes from './components/GodJul/SnowFlakes';

class App extends Component {
		constructor () {
		super();
		this.state = {
			activated: {
				now: '',
				last: '',
			},
			activeView: {
				AboutPage:false,
				CookiesPolicy: false,
				DorerOgVinduerPage: false,
				GulvPage: false,
				FrontPage: true,
				GarderoberPage: false,
				KontaktPage: false,
				TomrerarbeiderPage: false,
				OmOss: false,
				TrappaOrder: false,
				TrappaPage: false,
			},
			change: false,
			description: {
				TrappaPage: 'TRAPP',
				GulvPage: 'GULV',
				GarderoberPage: 'GARDEROBE OG KJØKKEN',
				DorerOgVinduerPage: 'DØR OG VINDU',
				TomrerarbeiderPage: 'TØMRERARBEIDEN',
				TrappaOrder: 'TRAPPETILBUD'
			},
			setByKontaktRef: false,
			socialPopupClose: false,
		}
		this.handleViewChange = this.handleViewChange.bind(this);
		this.closeSocialPopup = this.closeSocialPopup.bind(this);
		this.setActive = this.setActive.bind(this);
		this.handshake = this.handshake.bind(this);
	}

	setActive(id, name){
		let activeView = {
				AboutPage:false,
				CookiesPolicy: false,
				DorerOgVinduerPage: false,
				GulvPage: false,
				FrontPage: false,
				GarderoberPage: false,
				KontaktPage: false,
				TomrerarbeiderPage: false,
				OmOss: false,
				TrappaOrder: false,
				TrappaPage: false,
			};
    if (name)
    	window.scrollTo(0,350);
    else
    	window.scrollTo(0,20);

    	activeView = {
	    	...activeView,
	    	[id] : true
	    }
		let activated = {
			last: this.state.description[id] ? this.state.activated.now : this.state.activated.now, 
			now: this.state.description[id] ? id : '',
		};
		let setByKontaktRef = name ? true : false;
		let change = activated.last !== this.state.activated.now ? true : false;
    this.setState({
    	...this.state,
    	activated,
      activeView,
      setByKontaktRef,
      change
    });
	}

	closeSocialPopup() {
    this.setState({
    	socialPopupClose: true
    });
  }		

	handleViewChange(event) {
    const target = event.target.parentNode;
    const id = target.id ? target.id : event.target.id;
    const name = event.target.getAttribute('name');
	this.setActive(id, name);
	const inquiry = {uid: 'DAD01', name};
	const address = {
		local: 'http://127.0.0.1:3003/api/lc/usagereport', 
		global: 'https://laskowskicreative.no/api/lc/usagereport'}
	fetch(address.global, {
		method: 'POST',
		headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
		},
		body: JSON.stringify(inquiry)
	});
  }

  handshake() {
    const inquiry = {uid: 'DAD01'};
    const address = {
        local: 'http://127.0.0.1:3003/api/lc/handshake', 
        global: 'https://laskowskicreative.no/api/lc/handshake'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiry)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
      {
        activatePage: json.handshake
      }
      ))  
    }

	componentDidMount(){
		this.handshake();
	}

	render() {
	const cookies = new Cookies();
	const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
	let socialPopupActivated = setTimeout(()=>true, 3000);
	//timer(socialPopupActivated);
	//console.log(socialPopupActivated);
  return (
  	<div>
  	{socialPopupActivated > 5 && !this.state.socialPopupClose && <SocialPopup closeSocialPopup={this.closeSocialPopup}/>}
  	<div className="App-nav"  style={{position: 'fixed', top: 0, left: 0, height: 'auto'}}><NavigationBar handleViewChange={this.handleViewChange}/></div>
    
{
	//<GodJulPopup/>
	//<SnowFlakes/>
}
{
	/*
		activeView: {
		AboutPage:false,
		CookiesPolicy: false,
		DorerOgVinduerPage: false,
		GulvPage: false,
		FrontPage: true,
		GarderoberPage: false,
		KontaktPage: false,
		TomrerarbeiderPage: false,
		OmOss: false,
		TrappaOrder: false,
		TrappaPage: false,
	},
	*/
}
	  <Router>
        <Routes>
		  <Route exact path="/dorerogvinduer" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'DorerOgVinduerPage',
			}
		  }
		  activeView={{DorerOgVinduerPage: true}}
		  />}>
          </Route>
		  <Route exact path="/gulv" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'GulvPage',
			}
		  }
		  activeView={{GulvPage: true}}
		  />}>
          </Route>
		  <Route exact path="/garderobe" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'GarderoberPage',
			}
		  }
		  activeView={{GarderoberPage: true}}
		  />}>
          </Route>
		  <Route exact path="/tomrerarbeider" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'TomrerarbeiderPage',
			}
		  }
		  activeView={{TomrerarbeiderPage: true}}
		  />}>
          </Route>
		  <Route exact path="/trapp" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'TrappaPage',
			}
		  }
		  activeView={{TrappaPage: true}}
		  />}>
          </Route>
		  <Route exact path="/nytrapp" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'TrappaOrder',
			}
		  }
		  activeView={{TrappaOrder: true}}
		  />}>
          </Route>
		  <Route exact path="/about" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'AboutPage',
			}
		  }
		  activeView={{AboutPage: true}}
		  />}>
          </Route>
          <Route exact path="/kontakt" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: 'KontaktPage',
			}
		  }
		  activeView={{KontaktPage: true}}
		  />}>
          </Route>
          <Route exact path="/" element={<MainContentSection 
		  handleViewChange={this.handleViewChange}
		  setByKontaktRef={this.state.setByKontaktRef}
			change={this.state.change}
		  activated={
			{
			last: '', 
			now: '',
			}
		  }
		  activeView={this.state.activeView}
		  />}>
          </Route>
        </Routes>
    </Router>
		{
			//<MainContentSection handleViewChange={this.handleViewChange} setByKontaktRef={this.state.setByKontaktRef} activated={this.state.activated} activeView={this.state.activeView}/>
		}
   {!cookiesAccepted && <CookiesPopup/>}


  </div>
		);
	}
}

export default App;
