import React, { Component } from 'react';
import './../../stylesheets/popup.css'

class SocialPopup extends Component {
	render() {
		return (
			<div className='social-popup'>
				<p className='social-popup-close-button' onClick={this.props.closeSocialPopup}>X</p>
				<img src='/lb_logotype.svg' className='social-logotype'/>
				<p><a href='https://instagram.com/laskowskibygg' target='_blank' ><img src='/social_ico_inst.png'/>Instagram</a></p>
			</div>
		) 
	}
}

export default SocialPopup;