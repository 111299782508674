import React, { Component } from 'react';
import AboutPage from './AboutPage';
import TrappaPage from './TrappaPage';
import TrappaOrder from './TrappaOrder';
import GulvPage from './GulvPage';
import GarderoberPage from './GarderoberPage';
import DorerOgVinduerPage from './DorerOgVinduerPage';
import TomrerarbeiderPage from './TomrerarbeiderPage';
import KontaktPage from './KontaktPage';
import FrontPage from './FrontPage';
import Header from './../header/Header';
import LogoType from './../header/LogoType';
import { Row, Container } from 'react-bootstrap';
import Footer from './../footer/Footer';
import './../../stylesheets/content.css'

class MainContentSection extends Component {
  constructor () {
  super();
	  this.state = {
			frontPageCategories: 
			[
				{
					name: 'TrappaPage',
					link: '/trapp/',
					ico: 'trapp.svg',
					caption: 'TRAPP',
					briefDescription: [
						'Vi selger trapper',	
						'Vi monterer trappene',	
						'Vi fornyer trappene',	
					],
					pageContent: [
						{
							image: {
								src: 'trapp_laskowski_bygg.jpg',
								zoom: 100,
								x: 1,
								y: 1,
							},
							link: 'trapp',
							caption: 'Trapp',
							content: [
								'Trapper er et veldig viktig element i huset, både praktisk, trygt og estetisk! En trapp laget av naturlig treverk gir en følelse av varme og uerstattelig, unik karakter i interiøret. I kombinasjon med metallelementer vil den tiltrekke seg oppmerksomhet og kan bli til husets smykke. Trapper kan lages i mange varianter, både når det gjelder utforming og materialvalg. Den kan tilpasses fritt husets stil.',
								'For å gi trappene en unik karakter, er det verdt å ta hensyn til den minste detalj. Våre kunder får innvirkning på nesten alle detaljer. Det som skiller våre produkter fra mange andre trappeprodusenter, er den høyeste kvaliteten i hver minste detalj. Hvert enkelt element i trappene våre er nøye utvalgt og ferdigbehandlet. Takket være mange års erfaring og omfattende kunnskap gir vi assistanse og profesjonell rådgivning på høyeste nivå.',
								'Riktig utforming av trapp avhenger av mange faktorer, for eksempel:',
								[
									'størrelse på trapperom',
									'trappehøyde',
									'mulighet for å feste vange',
									'brukervennlighet'
								],
								'Send oss en forespørsel og du får et gratis pristilbud.',
								'Vi vil veilede deg gjennom hele prosessen fra bestillingen, gjennom planlegging til trappemontering.',
								'Vi lager trapper i et enkelt- og multiløp. Vi tilbyr tretrapper på høyeste europeiske nivå. Vi har eksperter innen design og planlegging, måling, produksjon og montering som styrer hele prosessen. Vi gjennomfører trapp design i tre i en rekke stiler: tradisjonell, moderne eller minimalistisk. Vi oppfyller forventningene til de mest krevende kundene.',
								'Hvert produserte trappesett måles individuelt og diskuteres i detalj med kunden, noe som betyr at når man bestiller trapp, får de et produkt skreddersydd med tanke på dimensjoner og individuell overflatebehandling. Leveringstid er 4 til 6 uker fra mottatt bestilling.'
							],
						},
						{
							image: {
								src: 'rekkverk_laskowski_bygg.jpg',
								zoom: 100,
								x: 1,
								y: 1,
							},
							link: 'rekkverk',
							caption: 'Rekkverk',
							content: [
								'En vakker trapp er ikke bare en god konstruksjon og finish, men også en riktig valgt og nøye laget balustrade/ rekkverk. Det er et dekorativt og samtidig sikkerhetselement i trappen. For å ivareta sikkerheten til våre kunder tilbyr vi rekkverk som ikke bare er i samsvar med kjøperens smak og forventninger, men fremfor alt følger gjeldende standarder.', 
								[
									'rekkverk i trapper skal alltid monteres når høyden overstiger 50 cm,',
									'høyde på rekkverk i boligbygg skal være 90-110 cm,',
									'høyden på rekkverk i offentlige bruksbygg skal være 110 cm.',
								],
								'Rekkverk, akkurat som trapper, er et dekorativt element inne i huset. Tre er det mest populære og samtidig det mest behagelige materialet som brukes til å bygge trapper. Vi tilbyr trerekkverk med rustfritt stålelementer og glass. Det er mange typer rekkverk, balustrer, strosser og stolper å velge mellom. Muligheten for å kombinere forskjellige materialer og mønstre er det perfekte supplement til trappene.', 
								'Ved bestilling får du en full oversikt og et tilbud på materialene som trengs for å fullføre din bestilling.', 
								'Vi lager rekkverk på trapper, mesaniner/ entresoler, landinger og balkonger samt sikkerhetsporter for barn.'
							],
						},
						{
							image: {
								src: 'nestetrinn_laskowski_bygg.jpg',
								zoom: 100,
								x: 1,
								y: 1,
							},
							link: 'nestetrinn',
							caption: 'Neste trinn',
							content: [
								'2 cm tykke trappefornyelsestrinn er ment for å fornye trinn på trekonstruksjoner. Hvis du har tretrapp i en tilstand som ikke tillater renovering, er fornyelsestrinnet den perfekte løsningen. Det lar deg dekke de gamle trinnene og er også veldig enkelt å installere.', 
								'Trinnene kan behandles med:',
								[
									'Lakk - har en hard, slitesterk glatt overflate som ikke krever pleie i daglig bruk.',
									'Olje - trenger dypt inn i trestrukturen samt gir naturlig varme. Du må ta vare på oljede elementer. Oljet trinn er ikke så slitesterk, men du kan forbedre utseendet på egenhånd.',
								],
								'Vi lager alle elementene som trengs for å montere trapp (trinn, repos, stusstrinn, landinger, listverk osv.)', 
							],
						},
						{
							image: {
								src: 'tre_laskowski_bygg.png',
								zoom: 100,
								x: 1,
								y: 1,
							},
							link: 'tretype',
							caption: 'Type av tre',
							content: [
								'Trinnet er den mest utnyttbare delen av en trapp. Derfor bør det være laget av sterkt og holdbart løvtrevirke. Det er flere forskjellige materialer å velge mellom. Det kan være eik eller ask. Fordelen med denne løsningen er høy slitestyrke. Eiketrinn anbefales spesielt for montering på steder med mye bruk. Trinnet kan derfor være både pent og holdbart.', 
								'EIK- Hard, tung og meget slitesterk, det er et ideelt treslag som er velegnet til både innvendige og utvendige trapper. Eiketre er veldig hardt og derfor godt beskyttet mot slitasje, riper og mekaniske skader. Eiketrapper er perfekte for både toetasjes leiligheter og romslige eneboliger.',
								'ASK - det er et veldig hardt tre med pen overflate og høy motstandsdyktighet mot fuktighet. Den har en elegant farge som fungerer bra i de fleste innredninger. Asketrapper vil fungere bra i både sjeldent besøkte og intensivt brukte boliger.',
								'BØK - er et relativt slitesterkt og holdbart tre. Bøkens mest karakteristiske trekk er enkel forme, ingen synlige vekst-ringer, ensartet struktur og lys farge. Bøketrapper er først og fremst velegnet til moderne interiør der eik vil være for klassisk.',
								'FURU - nåletre er naturlig mykere. Bartrær (Nåletrær) er imidlertid mer motstandsdyktige mot ugunstige værforhold og angrep av sopp og insekter enn løvtrær, derfor kan de betraktes som et materiale for utetrapper. Du må imidlertid huske på deres vanlige impregnering og renovering.' 
							],
						},
					]
				},
				{
					name: 'GulvPage',
					link: '/gulv/',
					ico: 'gulv.svg',
					caption: 'GULV',
					briefDescription: [
						'Vi retter gulvet',
						'Vi legger gulv og parketter',
						'Vi installerer gulvlister'
					],
					pageContent: [
						{
							image: {
								src: 'gulvretting_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'gulvretting',
							caption: 'Gulvretting',
							content: [
								'Å legge gulvet krever ikke bare ferdigheter og ekspertise, men også riktig forberedelse av underlaget. Ofte, etter mange års bruk eller etter å ha overtatt en brukt eiendom, finner vi at gulvene er ujevne, har skråninger, buler og ufullkommenheter. Det er best å rive opp det gamle gulvet, lage en selvnivellerende avrettingsmasse, pusse, prime den og deretter legge et nytt gulv. Men hvis du ikke er ferdig til en så radikal utskifting av skadet gulv, kan vi prøve å mestre situasjonen ved å bruke billigere løsninger.',
								'Når du bygger et nytt hus eller utfører en totalrenovering, er det lettere å bestemme hvordan gulvkonstruksjonen skal se ut. Når man lager gulv, kan det brukes teknikker som:',
								[
									'Selvnivellerende avrettingsmasse - rask og effektiv tørking.',
									'Gulv på gulvbjelker - lettere gulvkonstruksjon. Mellomrommet mellom bjelkene er fylt med isolerende ull eller gulvvarme.',
									'Gulvet kan legges ved hjelp av et planeringssystem med kiler. Kilen muliggjør en stabil og plan gulvmontering.'
								]
							],
						},
						{
							image: {
								src: 'gulvlegging_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'tregulv',
							caption: 'Tregulv',
							content: [
								'Valg av et erfarent firma som driver med gulvmontering er vesentlig for dem som setter pris på profesjonell reparasjon med kort ventetid. Takket være dyktige fagmenn trenger du ikke å bekymre deg for noe.',
								'Vi montrer både flytende og limt tregulv. Vi tilbyr tjenester på høyeste nivå. Som proffer, gjør vi vårt ytterste for å sikre at hver kunde er fornøyd etter bruk av tjenestene våre. Vårt tilbud omfatter både heltregulv og flersjikts gulvkonstruksjoner.',
								[
									'Massivt tregulv (av heltre) lages av ulike treslag; mest vanlig er eik eller aske.',
									'Flersjiktsgulv er laget av et toppsjikt i eik og et underlag av kryssfiner eller løvtreved.',
								],
								'Slitasjelaget på flersjiktsplanke er ikke så tykt som i massive heltreplater, noe som reduserer antall renovering som kan gjøres gjennom årene. Strukturen til det flersjiktsplanke har en positiv effekt på dimensjonsstabilitet. Påfølgende lag har et årringene ordnet på en annen måte, noe som reduserer krymping og svelling i treet på grunn av endringer i fuktighetsinnholdet. Heltregulv anses å være mer følsomt for omgivelsene og utsatt for formendring.',
							],
						},
						{
							image: {
								src: 'parkettlegging_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'parkett',
							caption: 'Parkett',
							content: [
								'Parketten ser estetisk ut og passer perfekt inn i ethvert interiør. Til tross for at markedet domineres av komfortable og klassiske gulvpaneler, kommer stadig flere tilbake til parketten. Riktig lagt parkettgulv er ekstremt slitesterkt og motstandsdyktig mot slitasje og ulike typer skader.',
								'God parkettlegging er viktig ikke bare når det gjelder funksjonalitet eller estetikk - leggemetodene kan brukes til å visuelt forstørre eller redusere størrelsen av rommet.',
								'Eventuelle feil begått, for eksempel mangel på ekspansjonsfuge, for tynt lag av en ny avrettingsmasse, mangel på fuktighetsisolasjon eller gulvet lagt på et utilstrekkelig tørket underlag kan føre til at parkett må legges på nytt. Nøyaktig parkettlegging er en garanti for komfort ved bruk, holdbarhet og estetikk.'
							],
						},
						{
							image: {
								src: 'gulvlister_laskowski_bygg.jpg',
								zoom: 100,
								x: 1,
								y: 1,
							},
							link: 'gulvlister',
							caption: 'Listverk og foringer',
							content: [
								'Listverk som forbinder gulvet med veggen, utgjør en viktig og elegant finish på tregulvet. Den presise og nøyaktige monteringen av gulvlistene øker gulvets estetiske og funksjonelle verdier betydelig. For å feste gulvlister bruker vi monterings lim og spiker som drives inn med en spikerpistol. Hullene som er laget på denne måten tettes med akrylmasse på kundens forespørsel.',
								'Som standard monterer vi gulvlister og gulvskokler 3-7 cm høye. Stadig oftere monterer vi finerte MDF-lameller malt i fargen som benyttes på veggene. Vi tilbyr også dekorativ design etter en individuell bestilling.',
								'På kundens forespørsel vil vi hjelpe med å velge og levere listverk.'
							],
						},
					]
				},
				{
					name: 'GarderoberPage',
					link: '/garderobe/',
					ico: 'garderober.svg',
					caption: 'GARDEROBER OG KJØKKEN',
					briefDescription: [
						'Vi skal designe en ny garderobe.', 
						'Vi vil demontere den gamle garderoben.', 
						'Vi installerer den nye garderoben din.'
					],
					pageContent: [
						{
							image: {
								src: 'garderober_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'garderober',
							caption: 'Garderober',
							content: [
								'Som fagmenn gir vi profesjonelle råd om valg av alle nødvendige elementer. Garderober er gjennomtenkte til minste detalj. Vi er oppdatert på de nyeste trendene og følger moderne løsninger som brukes i møbelindustri for å kunne vise våre kunder så mange muligheter som mulig. Med skreddersydde, måltilpassede løsninger, blir garderoben tilpasset både dine praktiske og estetiske behov.',
								'Innsiden av et garderobeskap kan utstyres med:',
								[
									'Hyller',
									'Kleshengere',
									'Skuffer',
									'Kurver',
									'Garderobe pantografer',
								],
								'Alle disse elementene vil gjøre det lett å segregere og organisere alle klær og tilbehør. I garderober kan du samle alle tingene dine, noe som gjør hjemmet ditt ryddig og pent. Garderobeskapet kan ha til og med plass for oppbevaring av husholdningsapparater, for eksempel en støvsuger eller strykebrett.',
								'Etter å ha beskrevet idéene dine, vil vi lage et lekkert design og du får akkurat den garderoben du drømmer om, skreddersydd til dine behov.'
							],
						},
						{
							image: {
								src: 'kjokken_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'kjokken',
							caption: 'Kjøkkenmøbler',
							content: [
								'Kjøkkenmøbler etter mål fungerer bra på mange kjøkken, særlig dem som er små og der det kreves spesielt god planløsning. Ved å bestille møbler etter mål kan man få det man ønsker både når det gjelder design, funksjonalitet, størrelse og farge.',
								'Først må man måle opp hvor stor plass en egentlig har.Det er viktig å ta seg god tid og få nøyaktige mål. Målene er grunnlaget for hele planleggingen.',
								'Vi er med deg i hver fase. Vi:',
								[
									'klargjør rommet og demonter gamle møbler',
									'oppmåler kjøkkenet',
									'handler og leverer materialer',
									'monterer møbler og fester dem til veggen',
									'monterer kjøkkenbenk',
									'kutter nødvendige hull i benken',
									'gjør eventuelle modifikasjoner på tilpassede skapet (øke eller redusere dybden, forkorte, kutte hull)',
									'hjelper med husholdningsapparater og hvitevarer',
									'rengjør og kjører bort søppel',
								],
							],
						},
					]
				},
				{
					name: 'DorerOgVinduerPage',
					link: '/dorerogvinduer/',
					ico: 'dorerogvinduer.svg',
					caption: 'DØR OG VINDU',
					briefDescription: [
						'Vi selger vinduer og dører',
						'Vi installerer dører, vinduer og takvinduer',
						'Vi programmerer vinduer med servomotor'
					],
					pageContent: [
						{
							image: {
								src: 'dorer_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'dorer',
							caption: 'Dorer',
							content: [
								'Trenger hjemmet ditt en ny dør?',
								'Alle nødvendige målinger som trenges før montering vil bli utført i ditt hjem. Velger du riktig modell, vil du også få masse gode råd fra oss.',
								'Et profesjonelt monteringsteam vil komme hjem til deg på avtalt dato. De skal levere produktet og ta det med til den oppgitte adressen. Du vil bli varslet på forhånd om hvor lenge tjenesten varer.',
								'Vi vil utføre alle nødvendige aktiviteter i forbindelse med dørmontering: vi installerer låser, håndtak, elektronisk dørlås og annet tilbehør.',
								'Vi forlater huset ryddig og i orden. Vi kan også ta med oss ditt gamle produkt for gjenvinning.',
							],
						},
						{
							image: {
								src: 'vinduer_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'vinduer',
							caption: 'vinduer',
							content: [
								'Velger du nye vinduer, bør du ikke glemme å ansette et profesjonelt montasjeteam. Det anslås at så mye som 90% av feil rapportert av brukere er forårsaket av feil montering. Vinduet kan være svært lydisolert og ha lav varmeoverføringskoeffisient, men hvis det er feil montert, vil det ikke fungere bra.',
								'For det første bør de nye vinduene oppmåles. I tilfeller hvor trevirket er gammelt og utypisk kan ytterligere arbeid for å justere vindusåpningen kreves.',
								'Arbeidet må utføres i samsvar med anbefalt teknologi og med stor forsiktighet. Monteringsmetoden må også tilpasses veggenes materiale, type isolasjon, samt ønsket finish.',
								'Festing av vinduet må sikre dets stabilitet under belastning som følge av sin egen vekt og dynamiske krefter forårsaket av vindlast, åpning og lukking.',
							],
						},
						{
							image: {
								src: 'takvinduer_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'takvinduer',
							caption: 'Takvinduer',
							content: [
							'Hvorfor er det verdt å velge et takvindu?',
							'En viktig fordel med denne løsningen er at tilgangen til takvinduet utenfra er ganske begrenset. På grunn av skråningen kan du være helt sikker på at ingen vil se på deg. Det er også viktig at vinduene som er plassert i et skrått tak ikke forstyrrer interiørarrangement på rommet. Takvinduer som for øyeblikket tilbys, er veldig brukervennlige takket være deres svingbare konstruksjon. Vanligvis kan takvinduer monteres på tak med forskjellige skråninger.'
							],
						},
						{
							image: {
								src: 'servmotor_vinduer_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 0,
							},
							link: 'servmotor',
							caption: 'Servmotor vinduer',
							content: [
								'Takvinduet er utstyrt med et system med en servomotor som muliggjør fjernåpning ved hjelp av en fjernkontroll.', 
								'Alle installerte vinduer og annet elektrisk tilbehør (interne og eksterne rulleskodder, persienner eller markiser) kan føres inn i fjernkontrollens minne.',
								'Utstyrt med en regnsensor som automatisk aktiverer åpne/lukke-funksjon som lukker takvinduet automatisk ved nedbør.',
							],
						},
					]				
				},
				{
					name: 'TomrerarbeiderPage',
					link: '/tomrerarbeider/',
					ico: 'tomrerarbeider.svg',
					caption: 'TØMRERARBEIDEN',
					briefDescription: [
						'Vi skal designe og bygge en ny terrasse',
						'Installasjon av huskonstruksjoner og tretak', 
						'utskifting av panelfasade'
					],
					pageContent: [
						{
							image: {
								src: 'terrasser_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 50,
							},
							link: 'terrasser',
							caption: 'Terrasser',
							content:[
								'Terrasse er en planert avsats foran fasade. Fra terrassen er det vanligvis inngang til stua og trapp til nedenforliggende hage, så på den måten forbinder den hagen med et hjeminteriør. Enkel å vedlikeholde, holdbar, motstandsdyktig, fasjonabel og funksjonell - slik skal en terrasse være. Vi veileder og viser hvordan å planlegge et funksjonelt og elegant rom som vil bli en utvidelse av stuen i hagen.',
								'Hageterrasser må ikke bare tåle møblenes konstante bruk og vekt. I tillegg bør de noen ganger tåle ekstreme værforhold og temperaturendringer. Tilkobling og utjevning av overflaten er ekstremt viktig for at byggverket skal være holdbart og trygt å bruke.',
								'Hvis du vil at terrassen skal se tradisjonell og elegant ut, bruk et terrassebord i tre. Dekket med et passende impregneringsmiddel, er det riktig beskyttet mot fuktighet, frost og skadedyr. Alternativt kan du velge kompositt terrassebord, som i motsetning til heltre viser bedre holdbarhet og motstand mot forskjellige værforhold.',
								'Terrassetak forbedrer funksjonaliteten betydelig. Det er flere måter å lage tak på, avhengig av arbeidskraft og ressurser kan man installere en markise, lett struktur eller en komplett terrasse oppbygging.'
								],
						},
						{
							image: {
								src: 'vinterhage_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 50,
							},
							link: 'Vinterhage',
							caption: 'Vinterhage',
							content:[
								'Lyse, romslige vinterhager er den perfekte løsningen for våre moderne omgivelser som blir mer koselig og gir et sted for ekte avslapning.',
								'Vi legger stor vekt på det effektive og funksjonelle utseendet til den designede vinterhagen. Vi tilbyr omfattende tjenester fra planlegging til ferdigstilling. Vi lager vinterhager for å oppfylle forventningene til de mest krevende kunder som ønsker å få et unikt sted for sine nærmeste hvor man virkelig kan slappe av og møte på vinterdager.'
								],
						},
						{
							image: {
								src: 'takkonstruksjon_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 50,
							},
							link: 'trekonstruksjon',
							caption: 'Trekonstruksjon',
							content:[ 
								'Til ulike oppgaver i konstruksjon',
								'Dette gjelder alt fra tømrer, snekring til taktekking',
								'Montering konstruksjon av tre vegger, tak og hus',
								'Montering jording på teknisk tegning',
								'Utskifting av ødelagte elementer i tømmerhus',
								],
						},
						{
							image: {
								src: 'inpanel_laskowski_bygg.jpg',
								zoom: 100,
								x: 0,
								y: 50,
							},
							link: 'paneler',
							caption: 'Paneler',
							content:[
									'Legging av veggpaneler er et av rekke alternativer for etterbehandling av vegger. Veggpaneler i tre er så universelle at de passer til ethvert rom og stil.',
									'Veggpaneler kan være laget av:',
									[
										'Veggpaneler i tre - laget av forskjellige tresorter: furu, eik eller gran. De er tilgjengelige i form av avlange planker. De preges av høy motstand mot smuss og riper.',
										'MDF veggpaneler - laget av paneler med synlig trestruktur, de er et billigere alternativ til tre-paneler.'
									]
								],
						},
					]
				},
			]
	  }
  }



	render() {
		return (
		<Container fluid>
			<header className="App-header">
				<Header/>
				<LogoType activated={this.props.activated} change={this.props.change} show={this.props.activeView.FrontPage || this.props.activeView.KontaktPage || this.props.activeView.AboutPage ? true : false }/>
			</header>
			<Row className="justify-content-md-center" style={{height: 'auto'}}>
				<div className='main' style={window.innerHeight > window.innerWidth ? {top: '300px'} : {top: '400px'}}>
					{this.props.activeView.TrappaPage && <TrappaPage handleViewChange={this.props.handleViewChange} pageData={this.state.frontPageCategories[0]}/>}
					{this.props.activeView.GulvPage && <GulvPage handleViewChange={this.props.handleViewChange} pageData={this.state.frontPageCategories[1]}/>}
					{this.props.activeView.GarderoberPage && <GarderoberPage handleViewChange={this.props.handleViewChange} pageData={this.state.frontPageCategories[2]}/>}
					{this.props.activeView.DorerOgVinduerPage && <DorerOgVinduerPage handleViewChange={this.props.handleViewChange} pageData={this.state.frontPageCategories[3]}/>}
					{this.props.activeView.TomrerarbeiderPage && <TomrerarbeiderPage handleViewChange={this.props.handleViewChange} pageData={this.state.frontPageCategories[4]}/>}
					{this.props.activeView.KontaktPage && <KontaktPage setByKontaktRef={this.props.setByKontaktRef} kontaktCategories={this.state.frontPageCategories.map((category,index)=>{return {ico: category.ico, caption: category.caption, name: category.name} })}/>}		
					{this.props.activeView.TrappaOrder && <TrappaOrder/>}
					{this.props.activeView.AboutPage && <AboutPage/>}
					{this.props.activeView.FrontPage && <FrontPage handleViewChange={this.props.handleViewChange} frontPageCategories={this.state.frontPageCategories}/>}					
				<div>
				<Footer/>
				<div className='footer-bottom-line'>
				</div>
				</div>
				</div>
			</Row>
		</Container>
		) 
	}
}

export default MainContentSection;