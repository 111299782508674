import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import './../../../stylesheets/forms.css'

class StairFinish extends Component {
  constructor () {
    super();
    this.state = {
      woods: [
        {
          caption: 'Bøk',
          img: 'bok.jpg',
          id: 'wood0',
          name: 'wood',
          type: 'radio',
          popular: false,
        },
        {
          caption: 'Ask',
          img: 'ask.jpg',
          id: 'wood1',
          name: 'wood',
          type: 'radio',
          popular: true,
        },
        {
          caption: 'Eik',
          img: 'eik.jpg',
          id: 'wood2',
          name: 'wood',
          type: 'radio',
          popular: true,
        },
        {
          caption: 'Furu',
          img: 'furu.jpg',
          id: 'wood4',
          name: 'wood',
          type: 'radio',
          popular: false,
        },
      ],
      finishes: [
        {
          caption: 'Slipt',
          id: 'finish0',
          name: 'finish',
          type: 'radio',
        },
        {
          caption: 'Lakkert',
          id: 'finish1',
          name: 'finish',
          type: 'radio',
        },
        {
          caption: 'Beis + lakk',
          id: 'finish2',
          name: 'finish',
          type: 'radio',
        },
        {
          caption: 'Oljet',
          id: 'finish3',
          name: 'finish',
          type: 'radio',
        },
        {
          caption: 'Malt i RAL-farge',
          id: 'finish4',
          name: 'finish',
          type: 'radio',
        },
      ]
      }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.scrollToFinish= this.scrollToFinish.bind(this);
  }

  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
	}
	scrollToFinish = () => {
  	this.finishSection.scrollIntoView({ behavior: "smooth" });
	}

  handleUserInput(event){
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	if (name === 'finish')
  		this.scrollToBottom();
  	else
  		this.scrollToFinish();
  	this.props.saveValues(forwardObject);
  }
	render() {
		return (
      <fieldset>
        <Form.Group as={Row} className="justify-content-sm-center">
          <Form.Label column sm={2}>
            TRESORTER
          </Form.Label>
          <Col sm={10}>
          {this.state.woods.map((wood, index)=>(
            <Form.Label column sm={4} htmlFor={wood.id} key={index}>
              {wood.popular && <p class='formPopularChoice'>populært valg</p>}
              <div className='radioButtonForm'>
              <Form.Check id={wood.id} type={wood.type} name={wood.name} checked={this.props.wood===wood.id} onClick={this.handleUserInput}/>
              {wood.img && <img src={'/form_icons/'+wood.img}/>}
              <p>{wood.caption}</p>
              </div>
            </Form.Label>        
          ))}
          </Col>
        </Form.Group> 
        <Form.Group as={Row} className="justify-content-sm-center" ref={(el) => { this.finishSection = el; }} >
          <Form.Label column sm={2}>
            OVERFLATEBEHANDLING
          </Form.Label>
          <Col sm={10}>
          {this.state.finishes.map((finish, index)=>(
            <Form.Label column sm={4} htmlFor={finish.id} key={index}>
              <div className='radioButtonForm'>
              <Form.Check id={finish.id} type={finish.type} name={finish.name} checked={this.props.finish===finish.id} onClick={this.handleUserInput} />
              {finish.img && <img src={finish.img}/>}
              <p>{finish.caption}</p>
              </div>
            </Form.Label>        
          ))}
          </Col>
        <Col sm={12}>
          <Button ref={(el) => { this.nextButton = el; }} style={(!this.props.wood || !this.props.finish) ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}}  as={Col} variant='outline-dark'  name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
        </Col>
        </Form.Group>
      </fieldset> 
		) 
	}
}

export default StairFinish;