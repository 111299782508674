import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class KontaktCategoryItem extends Component {
	  constructor () {
    super();
    this.state = {};
  }
	render() {
		const order = this.props.messageId;
		return (
			<div>
				<Row>
					<Col sm={12}>
					<h1>BEKREFTELSE</h1>
					</Col>
					<Col sm={6}>
						
						<p>{'Skjema id: ' + this.props.messageId + ' sendt.'}</p>					
					</Col>
					<Col sm={6}>
						<p>Takk for at du kontakter oss!</p>   
					</Col>
          <Button>OK</Button>
				</Row>
			</div>
		) 
	}
}

export default KontaktCategoryItem;