import React, { Component } from 'react';
import CategoryBrief from './CategoryBrief';

import './../../stylesheets/content.css'

class FrontPage extends Component {

	render() {
		const frontPageCategories = this.props.frontPageCategories;
		return (
			<div className='frontPage'>
				{frontPageCategories.length >0 && frontPageCategories.map((category, index)=>(
						<CategoryBrief handleViewChange={this.props.handleViewChange} delay={"3"} key={index} category={category} />
					))}
			</div>
		) 
	}
}

export default FrontPage;