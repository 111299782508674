import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class ContactForm extends Component {
  constructor () {
    super();
    this.state = {
			contact: {name: false, lastname: false, mobile: false, email: false}
      }
      this.handleTextInput = this.handleTextInput.bind(this);
      this.handleUserInput = this.handleUserInput.bind(this);
    	this.scrollToBottom = this.scrollToBottom.bind(this);
	  }
	  scrollToBottom = () => {
	  	this.nextButton.scrollIntoView({ behavior: "smooth" });
	}
  handleTextInput (event) {
  	const value = event.target.value,
  		name = event.target.name,
  		forwardObject = {[name]: value};
  	this.props.saveValues(forwardObject);
  	}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }
	render() {
		return (
      <fieldset>
        <Form.Group as={Row} controlId="name">
          <Form.Label column sm={2}>
            Navn
          </Form.Label>
          <Col sm={10}>
            <Form.Control onChange={this.handleTextInput} value={this.props.name ? this.props.name : ''} name="name" type="text" placeholder="Navn" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lastname">
          <Form.Label column sm={2}>
            Etternavn
          </Form.Label>
          <Col sm={10}>
            <Form.Control onChange={this.handleTextInput} value={this.props.lastname ? this.props.lastname : ''} name="lastname" type="text" placeholder="Etternavn" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="email">
          <Form.Label column sm={2}>
            Email
          </Form.Label>
          <Col sm={10}>
            <Form.Control onChange={this.handleTextInput} value={this.props.email ? this.props.email : ''} name="email" type="email" placeholder="e-mail" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="mobile">
          <Form.Label column sm={2}>
            Telefon
          </Form.Label>
          <Col sm={10}>
            <Form.Control onChange={this.handleTextInput} value={this.props.mobile ? this.props.mobile : ''} type="text" name='mobile' placeholder="Telefon" />
          </Col>
        </Form.Group> 
        <Form.Group as={Row} controlId="message">
          <Form.Label column sm={2}>
            Melding
          </Form.Label>
          <Col sm={10}>
            <Form.Control onChange={this.handleTextInput} value={this.props.message ? this.props.message : ''} as="textarea" rows={7} type="text" name='message' placeholder="Melding" />
          </Col>
        </Form.Group>  
        <Form.Group as={Row} controlId="accept">
          <Form.Label column sm={{offset: 2, span: 8}}>
          <Form.Check onChange={this.handleUserInput} checked={this.props.accept ? true : null} style={{display: 'inline', marginRight: '15px'}} type="checkbox" name='accept'/>
            Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Laskowski Bygg for å kontakte deg og svare på det stilte spørsmålet.
          </Form.Label>
        </Form.Group>  
        <Button ref={(el) => { this.nextButton = el; }} style={!this.props.mobile || !this.props.name || !this.props.lastname || !this.props.email || (!this.props.message && this.props.progress===0) || !this.props.accept ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='next' id='next' onClick={this.props.sendForm}>Send</Button>
      </fieldset>
		) 
	}
}

export default ContactForm;