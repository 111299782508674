import React, { Component } from "react";
import { Button } from 'react-bootstrap';


class ConfirmButton extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }




  render (){
    const {clearMessageId} = this.props;
    return (
      <Button variant='outline-dark' as='Row' onClick={clearMessageId}>     
        <p>Gå tilbake</p>       
      </Button>
    );
  }
}

export default ConfirmButton;
