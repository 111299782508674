import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './../../stylesheets/footer.css'


class Footer extends Component {
	render() {
		const giveData = function (type){
			const data= {
				mail: 'kontakt@laskowskibygg.no',
    		mobile: '+4796861689'
    	};
    	return data[type];
		}
		return (
		<Row className='footer' style={{transform: window.innerHeight > window.innerWidth ? 'scale(0.4)' : 'scale(0.8)', marginBottom: window.innerHeight > window.innerWidth ? '-100px' : '', marginTop: window.innerHeight > window.innerWidth ? '-100px' : ''}}>
			<Col className='footer-logo' style={{marginLeft: window.innerHeight > window.innerWidth ? '60px' : '' }}>
				<img src='/lb_logo.svg' />
			</Col>
				<Col className='footer-logotype'>
					<img src='/lb_logotype.svg' />
				</Col>
			{window.innerHeight < window.innerWidth &&
			<Col>		
				<p>Lokebergveien 15</p>
				<p>1344 Haslum</p>
			</Col>}
			<Col style={{marginLeft: window.innerHeight > window.innerWidth ? '120px' : '' }}>		
				<a href={'tel:'+giveData('mobile')}>{giveData('mobile')}</a>
				<a href={'mailto:'+giveData('mail')}>{giveData('mail')}</a>
			</Col>
			<Col className='social-ico' style={{marginLeft: window.innerHeight > window.innerWidth ? '100px' : '', transform: window.innerHeight > window.innerWidth ? 'scale(0.7)' : 'scale(0.8)'}}>		
				<a href='https://facebook.com'><img src='/social_ico_fb.png' /></a>
				<a href='https://instagram.com/laskowskibygg'><img src='/social_ico_inst.png'/></a>
			</Col>
		</Row>
		) 
	}
}

export default Footer;