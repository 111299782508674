import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

class HoleDimensions extends Component {
  constructor () {
    super();
    this.state = {
			hole0: {a: false ,b: false},
			hole1: {a: false,b: false, c: false, d: false, e: false, f: false},
			hole2: {a: false,b: false, c: false, d: false},
			hole3: {a: false,b: false, c: false, d: false},
			hole4: {fi: false},
      }
      this.handleTextInput = this.handleTextInput.bind(this);
      this.handleNext = this.handleNext.bind(this);
    	this.scrollToBottom = this.scrollToBottom.bind(this);
    	this.checkValues = this.checkValues.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
	}
  handleTextInput (event) {
  	const value = event.target.value,
  		name = event.target.name;
  	this.setState({
  		[this.props.hole]: {
  		...this.state[[this.props.hole]],	
  		[name]: value
  	}
  	}, ()=> {
  		let correct = true;
  		let allDims = {H: false, HL: false, tak: false};
  		allDims = {...allDims, ...this.state[this.props.hole]}
	  	for (const [key, value] of Object.entries(allDims)) {
	  		correct = !value ? false : correct;
	  	}
	  	this.setState({
  			correct
  		})
	  }) 	
    
  }		
  checkValues = () => {
	if(this.props.holeDims)
		this.setState({
			correct: true,
			[this.props.hole]: {...this.props.holeDims}
		})
	}

  handleNext(){
  	const forwardObject = {holeDims: {...this.state[this.props.hole]}};
  	this.props.saveValues(forwardObject);
  	this.props.nextStep();
  }
  componentDidMount(){
  	this.scrollToBottom();
  	this.checkValues();
  }

	render() {
		const holeTypeDimensions = {
			hole0: ['a','b'],
			hole1: ['a','b', 'c', 'd', 'e', 'f'],
			hole2: ['a','b', 'c', 'd'],
			hole3: ['a','b', 'c', 'd'],
			hole4: ['fi'],
		},
		holeHeights = ['H', 'HL', 'tak'],
		dimensions = ['a', 'b', 'c', 'd', 'e', 'f', 'fi'],
    holes= {
    	hole0: 'hole_rectang.svg',
    	hole1: 'hole_m.svg',
     	hole2: 'hole_l_left.svg',
     	hole3: 'hole_l_right.svg',
    	hole4: 'hole_round.svg',    	
    };

		return (
		<Row>
			<Col lg={6} sm={12} className='dimensionDraw'>
			<Form.Label column sm={12}>{this.props.hole !== 'hole4' ? 'HULDIMENSJONER' : 'HULDIMENSJON'}</Form.Label>
			<img src={'/form_icons/'+holes[this.props.hole]} />
		  {dimensions.map((dimension, index)=>( 
      <Form.Group className='textBoxLabel' as={Row} controlId={"dimension"+dimension} hidden={!holeTypeDimensions[this.props.hole].includes(dimension)}>
        <Form.Label column sm={4}>
          {dimension === 'fi' ? 'ϕ (cm)' : dimension + '(cm)'} 
        </Form.Label>
        <Col sm={6} offset={1} >
          <Form.Control name={dimension} placeholder={dimension === 'fi' ? 'ϕ (cm)' : dimension + '(cm)'} value={this.state[this.props.hole][dimension] ? this.state[this.props.hole][dimension] : ''} type='number' onChange={this.handleTextInput} />
        </Col>
      </Form.Group>))}
      </Col>
      <Col lg={6} sm={12} className='dimensionDraw'>	
      <Form.Label column sm={12}>HULLEHØYDER</Form.Label>
      <img src='/form_icons/hole_height.svg'/>
		  {holeHeights.map((holeHeight, index)=>( 
      <Form.Group className='textBoxLabel' as={Row} controlId={"dimension"+holeHeight} >
        <Form.Label column sm={4}>
          {holeHeight+ ' (cm)'} 
        </Form.Label>
        <Col sm={6} offset={1}>
          <Form.Control name={holeHeight} placeholder={holeHeight + ' (cm)'} type='number' value={this.state[this.props.hole][holeHeight] ? this.state[this.props.hole][holeHeight] : ''} onChange={this.handleTextInput} />
        </Col>
      </Form.Group>))}
      </Col>
			<Col sm={12}>
				<Button ref={(el) => { this.nextButton = el; }} style={!this.state.correct ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='next' id='next' onClick={this.handleNext}>Neste</Button>
			</Col>				
		</Row>
		) 
	}
}

export default HoleDimensions;