import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class KontaktInfo extends Component {
	render() {
		const giveData = function (type){
			const data= {
				mail: 'kontakt@laskowskibygg.no',
    		mobile: '+4796861689'
    	};
    	return data[type];
		}
		return (
			<div>
				<Row className='kontakt-info justify-content-md-center'>
					<Col sm={12}>
					<h1>{'Vi er alltid glade for å svare på spørsmålene dine.'}</h1>
					</Col>
					<Col sm={12}lg={{offset:2, span:4}} style={{textAlign:'center'}}>
						<h2>{'Du kan kontakte oss på telefon fra mandag til fredag ​​9:00 - 18:00.'}</h2>
						<h3><a href={"tel:"+giveData('mobile')}>{giveData('mobile')}</a></h3>					
					</Col>
					<Col sm={12}lg={4} style={{textAlign:'center'}}>
						<h2>{'Skriv oss en e-post.'}</h2>
						<h3><a href={"mailto:"+giveData('mail')}>{giveData('mail')}</a></h3>   
					</Col>

					<Col sm={12}>
						<h2>{'Eller beskriv saken din i kontaktskjemaet: '}</h2>
					</Col>
				</Row>
			</div>
		) 
	}
}

export default KontaktInfo;