import React, { Component } from 'react';
import SubPageNavigationItem from './SubPageNavigationItem';

import {Col } from 'react-bootstrap';
import './../../stylesheets/content.css'

class SubPageNavigation extends Component {
	render() {
		const items= this.props.items;
		return (
			<div className='subpage-navigation'>
				{items.length > 0 && this.props.items.map((item, index)=>(
     			<SubPageNavigationItem item={item} key={index} />
         ))}
				
			</div>
		) 
	}
}

export default SubPageNavigation;