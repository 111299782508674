import React, { Component } from 'react';

import './../../stylesheets/content.css'

class CategoryBrief extends Component {
		constructor () {
		super();
		this.state = {
		}
		this.handleHover = this.handleHover.bind(this);
	}
	handleHover(name ,hoverState){
		this.setState({
			[name]: hoverState
		});
	}
	render() {
	const views = {
		defaultView: {
			front: 'show',
			back: 'hide'
		},
		hoveredView: {
			front: 'hide',
			back: 'show'
		}
	};
	const category = this.props.category;
		return (
		<a href={category.link}>
			<div className='category-brief' style={{animationDelay: this.props.delay+'s'}}
				onMouseEnter={() => this.handleHover(category.name, true)}
        onMouseLeave={() => this.handleHover(category.name, false)}>
				<div id={category.name}>
					<div 				onMouseEnter={() => this.handleHover(category.name, true)}
        onMouseLeave={() => this.handleHover(category.name, false)} onClick={this.props.handleViewChange} className={this.state[category.name] ? views.hoveredView.front : views.defaultView.front}>
						<img src={'/form_icons/'+category.ico} />
						<h1>{category.caption}</h1>
					</div>
					<div id={category.name} 	onMouseEnter={() => this.handleHover(category.name, true)}
        onMouseLeave={() => this.handleHover(category.name, false)} onClick={this.props.handleViewChange} className={this.state[category.name] ? views.hoveredView.back : views.defaultView.back}>
						{category.briefDescription.map((desc, idx)=>(
							<p onClick={this.props.handleViewChange}>{desc}</p>
							))}
					</div>
				</div>
			</div>
		</a>
		) 
	}
}

export default CategoryBrief;