import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';
import KontaktCategoryItem from './KontaktCategoryItem';

class KontaktCategoryGroup extends Component {
	  constructor () {
    super();
    this.state = {};
    this.handleUserInput = this.handleUserInput.bind(this);
  }
    handleUserInput(event){
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {
  			...this.props.categoryStatus,
  			[name]: !this.props.categoryStatus[name]}
  		;
  	this.props.selectCategory(forwardObject);
  }
	render() {
		const kontaktCategories = this.props.kontaktCategories;
		const categoryStatus = this.props.categoryStatus;
	//	const  = this.props.;
		return (
			<div id='contact-form'>
							  <Form.Group as={Row} controlId="category">
			          <Form.Label column sm={2}>
			            Du trenger ...
			          </Form.Label>
								<Col sm={10}>
					{
						kontaktCategories.length > 0 && kontaktCategories.map((category, index)=>(
									<div key={index} className='category-brief'
									style={{
										transform: categoryStatus[category.name.slice(0,-4)] ? 'scale(0.7)' : 'scale(0.55)',
										marginRight: '30px',
										marginLeft: '0',
										paddingLeft: '0',
										marginBottom: categoryStatus[category.name.slice(0,-4)] ? '190px' : '140px'}}>
										<div id={category.name.slice(0,-4)} 
					          			 >
											<div className='show' 
											style={{ 
												borderColor: categoryStatus[category.name.slice(0,-4)] ? '#000' : '#CC151A'
											}}
					          	>
												<img src={'/form_icons/'+category.ico} 	name={category.name.slice(0,-4)}
					          			onClick={this.handleUserInput} 
											style={{cursor: 'pointer'}} />
												<h1	name={category.name.slice(0,-4)} >{category.caption}</h1>
											</div>
										</div>
									</div>
							))
					}
							</Col>
				        </Form.Group>  
			</div>
		) 
	}
}

export default KontaktCategoryGroup;