import React, { Component } from 'react';
import SubPageNavigation from './SubPageNavigation';
import PageContent from './PageContent';
import KontaktSuggestion from './KontaktSuggestion';			
import './../../stylesheets/content.css'

class DorerOgVinduerPage extends Component {
	render() {
		return (
			<div>
				<SubPageNavigation items={this.props.pageData.pageContent}/>
				<PageContent pageContent={this.props.pageData.pageContent}/>
				<KontaktSuggestion need={'en ny dør eller et vindu'} referentName={'DorerOgVinduerPage'} form={'KontaktPage'} mail={'kontakt@laskowskibygg.no'} message={'Vi er alltid glade for å svare på spørsmålene dine. Trykk og fyll ut kontaktskjemaet.'} handleViewChange={this.props.handleViewChange}/>
			</div>
		) 
	}
}

export default DorerOgVinduerPage;