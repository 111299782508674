import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class AboutPage extends Component {
	render() {
		const giveData = function (name,type){
			const data= { 
				darek: {
					mail: 'darek@laskowskibygg.no',
	    		mobile: '+4796861689'
				},
				damian: {
					mail: 'damian@laskowskibygg.no',
					mobile: '+4748651666'
				}
			};
    	return data[name][type];
		}
		return (
			<div>
				<Row className='about-page justify-content-md-center'>
					<Col sm={{offset:2, span:8}} >
					<p>{'Laskowski Bygg er en familiebedrift drevet av far og sønn. I et tiår, her i Norge, har vi utviklet firmaet som tilbyr snekkertjenester til både private og forretningskunder. Fagkompetanse, 10 års erfaring og moderne produksjon, gjør vi kan levere kvalitet og en løsning du som kunde vil bli svært fornøyd med. Lang erfaring innen variert snekker og tømrer arbeid har resultert i et bredt spekter av tjenester som tilbys av oss. Fra montering av ulike tregulvtyper, gjennom produksjon og montering av trapp til skreddersydde garderober.'}</p>
					<p>{'I dag, som et firma med lange tradisjoner, med respekt for håndverk, fokuserer vi på tillit til det profesjonelle merkevarenavnet vi har utviklet. Vi gjør vårt ytterste for å garantere det høyeste servicenivået. Vi deler gjerne vår kunnskap og ekspertise. Til tross for mange års erfaring, lærer vi fremdeles hver dag. Tømmerindustrien blomstrer i dag, og vi elsker å være oppdatert på de nyeste trendene og moderne løsninger.'}</p>
					<p>{'For oss er respekt for kunden svært viktig. Når vi finner hva som er kundenes behov, gir vi alltid profesjonell støtte til dem. Kundens kunnskap om hva han eller hun trenger, kombinert med vår fagkunnskap, gjør at vi sammen leverer alt som passer perfekt til kunden. Vi er tilgjengelige på bestillings-, implementerings- og garantistadiet.'}</p>
					<p>{'Profesjonalitet. Vi tilfredsstiller alltid kravene som stilles til oss. Vi jobber flittig og effektivt. Som proffer bruker vi profesjonelle verktøy og moderne teknologi. Snekkeri i det 21. århundre er ikke bare en hammer og sag. Vi bruker lasernivåer, avstandsmålere og til og med datamodellering. Det er viktig for å få en vellykket kvalitetsimplementering.'}</p>
					<p>{'Ambisjoner. Vi liker ikke stagnasjon - mange års erfaring har oppfordret oss til å ta imot utfordringer. Vi har kjennskap til trevirkets hemmeligheter, liker interessante prosjekter og kan utføre det som virker umulig i begynnelsen.'}</p>
					<p>{''}</p>
					</Col>
				</Row>
				<Row className='about-us justify-content-md-center'>
					<Col sm={{offset:2, span:8}}  lg={{offset:2, span:4}} style={{textAlign:'center'}}>
						<img src="/imgs/d.jpg" alt='Darek Laskowski'/>
						<p>{'Darek er hovedspesialist og ekspert innen tekniske forhold'}</p>
						<h3><a href={"tel:"+giveData('darek' ,'mobile')}>{giveData('darek' ,'mobile')}</a></h3>
						<h3><a href={"mailto:"+giveData('darek' ,'mail')}>{giveData('darek' ,'mail')}</a></h3>
					</Col>
					<Col sm={{offset:2, span:8}} lg={{offset:2, span:4}} style={{textAlign:'center'}}>
						<p>{'Han har 35 års erfaring som snekker. I sin karriere bygde han mange hus. Tjenestevillig, hjelpsom og snill. Et skattkammer av kunnskap om standarder og regler i byggekunst. Uunnværlig i prosessen med å utvikle designdetaljer.'}</p> 
					</Col>
				</Row>
				<Row className='about-us justify-content-md-center'>
					<Col sm={{offset:2, span:8}} lg={{offset:2, span:4}} style={{textAlign:'center'}}>
						<img src="/imgs/dd.jpg" alt='Damian Laskowski'/>
						<p>{'Damian - ansvarlig for dokumentasjon, image og markedsføring i selskapet'}</p>
						<h3><a href={"tel:"+giveData('damian' ,'mobile')}>{giveData('damian' ,'mobile')}</a></h3>
						<h3><a href={"mailto:"+giveData('damian' ,'mail')}>{giveData('damian' ,'mail')}</a></h3> 
					</Col>
					<Col sm={{offset:2, span:8}} lg={{offset:2, span:4}} style={{textAlign:'center'}}>
						<p>{'På snekkerverksted siden barndommen. Hans lidenskap for snekkeri er resultat av mange års lærlingperiode hos faren. Som sin fars høyre hånd har han vært aktivt med å bygge merkevaren Laskowski Bygg siden 2012. Han tar seg av dokumentasjon, selskapets image, markedsføring og reklame. Han er svært interessert i Smarthus-teknologi. Han verdsetter estetiske verdier høyt og legger vekt på finish. Han har designet og laget denne hjemmesiden.'}</p>
					</Col>
				</Row>
			</div>
		) 
	}
}

export default AboutPage;