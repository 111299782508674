import React, { Component } from 'react';
import SubPageNavigation from './SubPageNavigation';
import PageContent from './PageContent';
import KontaktSuggestion from './KontaktSuggestion';			
import './../../stylesheets/content.css'

class TrappaPage extends Component {
	render() {
		return (
			<div>
				<SubPageNavigation items={this.props.pageData.pageContent}/>
				<PageContent pageContent={this.props.pageData.pageContent}/>
				<KontaktSuggestion need={'en ny trapp'} referentName={'TrappaPage'} form={'TrappaOrder'} mail={'trapp@laskowskibygg.no'} message={'Klikk her og fyll ut skjemaet for et gratis tilbud.'} handleViewChange={this.props.handleViewChange}/>
			</div>
		) 
	}
}

export default TrappaPage;