import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class OrderSumUp extends Component {
	  constructor () {
    super();
    this.state = {
    	kinds: {kind0: 'Tre trapp', kind1: 'Trapp på betongbase'},
    	types: {
    		type0:  {
          caption: 'Rett trapp',
          img: 'stair_straight.svg',
        },
        type1: {
          caption: 'L-trapp',
          img: 'stair_l.svg',
        },
        type2: {
          caption: 'L-trapp med repos',
          img: 'stair_l_land.svg',
        },
        type3: {
          caption: 'U-trapp',
          img: 'stair_u.svg',
        },
        type4: {
          caption: 'L-trapp med halv-repos',
          img: 'stair_l_half_land.svg',
        },
        type5: {
          caption: 'Repostrapp',
          img: 'stair_u_land.svg',
        },
        type6: {
          caption: 'Trappkombinasjon',
          img: 'stair_comb.svg',
        },
        type7: {
          caption: 'Spiraltrapp',
          img: 'stair_spiral.svg',
        },
        type8: {
          caption: 'Buet trapp',
          img: 'stair_flex.svg',
        },
      },
 			holes: {
    		hole0:  {
          caption: 'Rektangel',
          img: 'hole_rectang.svg',
        },
        hole1: {
          caption: 'M',
          img: 'hole_m.svg',
        },
        hole2: {
          caption: 'L-venstre',
          img: 'hole_l_left.svg',
        },
        hole3: {
          caption: 'L-høyre',
          img: 'hole_l_right.svg',
        },
        hole4: {
          caption: 'Rund',
          img: 'hole_round.svg',
        }
      },
      constructions: {
    		construction0:  {
          caption: 'Mellom vanger',
          img: 'construction_0.svg',
        },
        construction1: {
          caption: '„Pålagt” trapp',
          img: 'construction_1.svg',
        },
        construction2: {
          caption: 'Boltet',
          img: 'construction_2.svg',
        },
        construction3: {
          caption: 'Midtvange',
          img: 'construction_3.svg',
        },
        construction4: {
          caption: 'Tett trapp',
          img: 'construction_4.svg',
        },
        construction5: {
          caption: 'Flyttende trapp',
          img: 'construction_5.svg',
        }
      },
      risers: {
      	riser0: {
          caption: 'Uten stusstrinn',
          img: 'riser_0.svg',
        },
        riser1: {
          caption: 'Med stusstrinn',
          img: 'riser_1.svg',
        },
        riser2: {
          caption: 'L-formet barnesikringslister',
          img: 'riser_2.svg',
        },
      },
      railings: {
      	railing0: {
          caption: 'Horisontale lister',
          img: 'railing_1.svg',
        },
        railing1: {
          caption: '„Horisontale (metal) spiler',
          img: 'railing_2.svg',
        },
        railing2: {
          caption: 'Glass',
          img: 'railing_3.svg',
        },
        railing3: {
          caption: 'Baluster',
          img: 'railing_4.svg',
        },       	
      },
      woods: {
    		wood0:  {
          caption: 'Bøk',
          img: 'bok.jpg',
        },
        wood1: {
          caption: 'Ask',
          img: 'ask.jpg',
        },
        wood2: {
          caption: 'Eik',
          img: 'eik.jpg',
        },
        wood3: {
          caption: 'Lønn',
          img: 'lonn.jpg',
        },
        wood4: {
          caption: 'Furu',
          img: 'furu.jpg',
        }
      },
      finishes: {
    		finish0:  'Slipt',
        finish1: 'Lakkert',
        finish2: 'Beis + lakk',
        finish3: 'Oljet',
        finish4: 'Malt i RAL-farge'      	
      }      	
    }
  }

	render() {
		const state = this.state;
		const order = this.props.order;
		const getDimensions = (holeDims) => {
			let dimensions = [];
	  	for (const [key, value] of Object.entries(holeDims)) {
	  		dimensions.push({caption: key, value: value});
	  	}
	  	return dimensions;
		}
		const dimensions = getDimensions(order.holeDims);
		console.log(dimensions);
		return (
			<div className='formSummary'>
				<Row>
					<Col sm={12}>
					<h1>TRAPPA</h1>
					</Col>
					<Col sm={6}>
						<img src={'/form_icons/'+this.state.types[order.type].img}/>
						<p>{this.state.types[order.type].caption}</p>					
					</Col>
					<Col sm={6}>
						<p>{this.state.kinds[order.kind]}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
					<h1>HULL</h1>
					</Col>
					<Col sm={6}>
						<img src={'/form_icons/'+this.state.holes[order.hole].img}/><br/><img src='/form_icons/hole_height.svg' style={{marginTop: '20px'}}/>			
					</Col>
					<Col sm={6}>
					{dimensions.map((dimension, index)=>(
						<p key={index}>{(dimension.caption === 'fi' ? 'ϕ' : dimension.caption)+': ' +dimension.value+' cm'}</p>
						))}
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<h1>TRAPPEKONSTRUKSJON</h1>
					</Col>
					<Col sm={6}>
					<img src={'/form_icons/'+this.state.constructions[order.construction].img}/>
						<p>{this.state.constructions[order.construction].caption}</p>		
					</Col>
					<Col sm={6}>
					<img src={'/form_icons/'+this.state.risers[order.riser].img}/>
						<p>{this.state.risers[order.riser].caption}</p>	
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
					<h1>REKKVERK</h1>
					</Col>
					<Col sm={6}>
					<img src={'/form_icons/'+this.state.railings[order.railing].img}/>
						<p>{this.state.railings[order.railing].caption}</p>	
					</Col>
					{this.props.order.extraRailing && <Col sm={6}>
					<h2>REKKVERK I TRAPPEÅPNING</h2>
						<p>{this.props.order.extraRailing+' m'}</p>						
					</Col>}
				</Row>
				<Row>
					<Col sm={12}>
						<h1>TRESORTER</h1>	
					</Col>
					<Col sm={6}>
					<img src={'/form_icons/'+this.state.woods[order.wood].img}/>
						<p>{this.state.woods[order.wood].caption}</p>	
					</Col>
					<Col sm={6}>
						<p>{this.state.finishes[order.finish]}</p>	
					</Col>
				</Row>
			</div>
		) 
	}
}

export default OrderSumUp;