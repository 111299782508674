import React, { Component } from 'react';
import NavItem from './NavItem';
import { Nav, Navbar } from 'react-bootstrap';

import './../../stylesheets/nav.css'
class NavigationBar extends Component {
	constructor(){
	super();
	this.state = {
		navItems: [
			{link: '/kontakt', caption: 'KONTAKT OSS'},
		],
		pageY: 0,
		logoClass: 'nav-logo-white',
		};
	this.activateLogoInNavbar = this.activateLogoInNavbar.bind(this);
	this.setClassOver = this.setClassOver.bind(this);
	this.setClassOut = this.setClassOut.bind(this);
	}

	activateLogoInNavbar() {
		const getPageYposition = ()=>{setInterval(3, this.setState({pageY: window.pageYOffset}))};
		getPageYposition();

		//console.log(this.state.pageY);
		
	}
	setClassOver() {
		this.setState({
			logoClass: 'nav-logo-black'
		})
	}
	setClassOut() {
		this.setState({
			logoClass: 'nav-logo-white'
		})
	}
	componentDidMount() {
		//this.activateLogoInNavbar();
		this.setState({
		pageY: 0,
		logoClass: 'nav-logo-white',			
		navItems: [
			{link: '/trapp/', caption: 'TRAPP', name: 'TrappaPage'},
			{link: '/gulv/', caption: 'GULV', name: 'GulvPage'},
			{link: '/garderobe/', caption: 'GARDEROBER OG KJØKKEN', name: 'GarderoberPage'},
			{link: '/dorerogvinduer/', caption: 'DØR OG VINDU', name: 'DorerOgVinduerPage'},
			{link: '/tomrerarbeider/', caption: 'TØMRERARBEIDEN', name: 'TomrerarbeiderPage'},
			{link: '/about/', caption: 'OM OSS', name: 'AboutPage'},
			{link: '/kontakt/', caption: 'KONTAKT OSS', name: 'KontaktPage'},
			{link: '/nytrapp/', caption: 'TRAPPETILBUD', name: 'TrappaOrder'},
		],
		})
	}


	render() {
		const navItems = this.state ? this.state.navItems : [{link: '/kontakt', caption: 'KONTAKT OSS'},];
		//const pageYvalue = this.state.pageY;
		const logoClass = this.state ? this.state.logoClass : 'nav-logo-white' ;
		return (
			<Navbar onMouseOver={this.setClassOver} onMouseOut={this.setClassOut} collapseOnSelect expand="lg">
				{<Navbar.Brand className={logoClass} href='/'>  
				  <img
	        	src="/lb_logo.svg"
	      	  alt="Laskowski Bygg"
	      	/>
	      </Navbar.Brand>}
			  <Navbar.Toggle aria-controls="lb-navbar" style={{position: 'fixed', top: 0, left: 0, width: '99vw', height: '60px', backgroundColor: '#CC151A' }}/>
				<Navbar.Collapse id="lb-navbar">
					<Nav>											
						{navItems.length > 1 && navItems.map((item, index)=>(
							<NavItem item={item} key={index} handleViewChange={this.props.handleViewChange}
							/>
						))}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		) 
/*
		this.props.rooms.map(room=>(
			<MenuItem 
				key={room.id} 
				room={room} 
				selected={this.props.selected}  
				menuHover= {this.props.menuHover} 
				menuHoverOff = {this.props.menuHoverOff} 
				menuHoverOn = {this.props.menuHoverOn}
				promoHover= {this.props.promoHover} 
				promoHoverOff = {this.props.promoHoverOff} 
				promoHoverOn = {this.props.promoHoverOn}  
				showRoomDetails = {this.props.showRoomDetails} />
		));*/
	}
}

export default NavigationBar;