import React, { Component } from "react";
import { Button } from 'react-bootstrap';


class OrderBefaringButton extends Component {
  constructor () {
	  super();
	  this.state = {
    }
  }

  componentDidMount() {
  }




  render (){
    const {sendForm} = this.props;
    return (
      <Button variant='outline-dark' as='Row' onClick={sendForm}>     
        <p>Bestill Befaring</p>       
      </Button>
    );
  }
}

export default OrderBefaringButton;
