import React, { Component } from 'react';
import SubPageNavigation from './SubPageNavigation';
import PageContent from './PageContent';
import KontaktSuggestion from './KontaktSuggestion';			
		
import './../../stylesheets/content.css'
import OrderBefaring from './OrderBefaring';

class GulvPage extends Component {
	render() {
		return (
			<div>
				<SubPageNavigation items={this.props.pageData.pageContent}/>
				<PageContent pageContent={this.props.pageData.pageContent}/>
				<OrderBefaring/>
				<KontaktSuggestion need={'et nytt gulv'} form={'KontaktPage'} referentName={'GulvPage'} mail={'kontakt@laskowskibygg.no'} message={'Vi lager alle typer gulv. Trykk og fyll ut kontaktskjemaet.'} handleViewChange={this.props.handleViewChange}/>
			</div>
		) 
	}
}

export default GulvPage;