import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import './../../../stylesheets/forms.css'

class StairConstruction extends Component {
  constructor () {
    super();
    this.state = {
      constructions: [
        {
          caption: 'Mellom vanger',
          img: 'construction_0.svg',
          id: 'construction0',
          name: 'construction',
          type: 'radio',
        },
        {
          caption: '„Pålagt” trapp',
          img: 'construction_1.svg',
          id: 'construction1',
          name: 'construction',
          type: 'radio',
        },
        {
          caption: 'Boltet',
          img: 'construction_2.svg',
          id: 'construction2',
          name: 'construction',
          type: 'radio',
        },
        {
          caption: 'Midtvange',
          img: 'construction_3.svg',
          id: 'construction3',
          name: 'construction',
          type: 'radio',
        },
        {
          caption: 'Tett trapp',
          img: 'construction_4.svg',
          id: 'construction4',
          name: 'construction',
          type: 'radio',
        },
        {
          caption: 'Flyttende trapp',
          img: 'construction_5.svg',
          id: 'construction5',
          name: 'construction',
          type: 'radio',
        },
      ]
      }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }
	render() {
		return (
        <Form.Group as={Row} className="justify-content-sm-center">
          {this.state.constructions.map((construction, index)=>(
            <Form.Label column sm={4} htmlFor={construction.id} key={index}>
              <div className='radioButtonForm'>
              <Form.Check id={construction.id} type={construction.type} name={construction.name} checked={this.props.construction===construction.id} onClick={this.handleUserInput}/>
              {construction.img && <img src={'/form_icons/'+construction.img}/>}
              <p>{construction.caption}</p>
              </div>
            </Form.Label>        
          ))}
          <Col sm={12}>
          	<Button ref={(el) => { this.nextButton = el; }} style={!this.props.construction ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
          </Col>
        </Form.Group> 
		) 
	}
}

export default StairConstruction;