import React, { Component } from 'react';
import SubPageNavigation from './SubPageNavigation';
import PageContent from './PageContent';
import KontaktSuggestion from './KontaktSuggestion';			
	
import './../../stylesheets/content.css'

class GarderoberPage extends Component {
	render() {
		return (
			<div>
				<SubPageNavigation items={this.props.pageData.pageContent}/>
				<PageContent pageContent={this.props.pageData.pageContent}/>
				<KontaktSuggestion need={'en ny garderobe eller et kjøkken'} referentName={'GarderoberPage'} form={'KontaktPage'} mail={'kontakt@laskowskibygg.no'} message={'Vi vil gjøre ditt kjøkken og garderobe av drømmer! Trykk og fyll ut kontaktskjemaet.'} handleViewChange={this.props.handleViewChange}/>
			</div>
		) 
	}
}

export default GarderoberPage;