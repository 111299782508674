import React, { Component } from 'react';
import './../../stylesheets/header.css'
class LogoType extends Component {
	constructor (props) {
		super(props);
		this.state = {
			activated: {
				now: '',
				last: '',
			},
			activeView: {
				TrappaPage: 'TRAPP',
				GulvPage: 'GULV',
				GarderoberPage: 'GARDEROBER OG KJØKKEN',
				DorerOgVinduerPage: 'DØR OG VINDU',
				TomrerarbeiderPage: 'TØMRERARBEIDEN',
				TrappaOrder: 'TRAPPETILBUD'
			},
			change: false,
			logotypeClass: 'logotype',
			hiddenLogotypeClass: 'logotype-hide'
		}
	}

	render() {
				/*const header = <div id='header-wrapper'>
					{this.props.activated.now !== '' && <div className={this.state.logotypeClass}>
						<h1>
						{this.props.activated.now}
						</h1>									
					</div>}
					{this.props.activated.last !== '' && <div className={this.state.hiddenLogotypeClass}>
						<h1>{this.props.activated.last}</h1>
					</div>
					}
				</div>*/

				const activatedNow = (now) => {
					switch (now) {
					  case 'TrappaPage':
					    const TrappaHeader = <div id={now+'Header'} className={this.state.logotypeClass}>
									<h1>
									{this.state.activeView[now]}
									</h1>									
								</div>;
							return TrappaHeader;
					    break;
					  case 'GulvPage':
					    const GulvHeader = <div id={now+'Header'} className={this.state.logotypeClass}>
									<h1>
									{this.state.activeView[now]}
									</h1>									
								</div>;
							return GulvHeader;
					    break;
					  case 'GarderoberPage':
					    const GarderoberHeader = <div id={now+'Header'} className={this.state.logotypeClass}>
									<h1>
									{this.state.activeView[now]}
									</h1>									
								</div>;
							return GarderoberHeader;
					    break;
					  case 'DorerOgVinduerPage':
					    const DoVHeader = <div id={now+'Header'} className={this.state.logotypeClass}>
									<h1>
									{this.state.activeView[now]}
									</h1>									
								</div>;
							return DoVHeader;
					    break;
					  case 'TomrerarbeiderPage':
					    const TomrerarbeiderHeader = <div id={now+'Header'} className={this.state.logotypeClass}>
									<h1>
										{this.state.activeView[now]}
									</h1>									
								</div>;
							return TomrerarbeiderHeader;
					    break;
					    case 'TrappaOrder':
					    const TrappaOrderHeader = <div id={now+'Header'} className={this.state.logotypeClass}>
									<h1>
										{this.state.activeView[now]}
									</h1>									
								</div>;
							return TrappaOrderHeader;
					    break;
					  default:
					    console.log(`Sorry, we are out of options.`);
					}
 				}
 				const header = activatedNow(this.props.activated.now);

		
		return (
			<div className='logotype-wrapper'>
				<div className={window.innerHeight < window.innerWidth ? this.props.show ? 'logotype' : 'logotype-hide' : this.props.show ? 'logotype-phone' : 'logotype-hide'}>
					<img src='/lb_logotype.svg'/>
				</div>
					{this.props && header}
			</div>
		) 
	}
}

export default LogoType;