import React, { Component } from 'react';
import './../../stylesheets/header.css'

class Header extends Component {
	render() {
		return (
			<div className='border'>
				<div className='logo'>
					<div className='logo-fill'>
						<div className='logo-content'>
							<a href='/' alt='laskowskibygg.no'><img src='/lb_logo.svg' /></a>
						</div>
					</div>
				</div>
			</div>
		) 
	}
}

export default Header;