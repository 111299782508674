import React, { Component, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import './../../../stylesheets/forms.css'

class StairType extends Component {
  constructor () {
    super();
    this.state = {
      types: [
        {
          caption: 'Rett trapp',
          img: 'stair_straight.svg',
          id: 'type0',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'L-trapp',
          img: 'stair_l.svg',
          id: 'type1',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'L-trapp med repos',
          img: 'stair_l_land.svg',
          id: 'type2',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'U-trapp',
          img: 'stair_u.svg',
          id: 'type3',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'L-trapp med halv-repos',
          img: 'stair_l_half_land.svg',
          id: 'type4',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'Repostrapp',
          img: 'stair_u_land.svg',
          id: 'type5',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'Trappkombinasjon',
          img: 'stair_comb.svg',
          id: 'type6',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'Spiraltrapp',
          img: 'stair_spiral.svg',
          id: 'type7',
          name: 'type',
          type: 'radio',
        },
        {
          caption: 'Buet trapp',
          img: 'stair_flex.svg',
          id: 'type8',
          name: 'type',
          type: 'radio',
        },
      ]
      }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }
  render() {
		return (
      <Form.Group as={Row} className="justify-content-sm-center">
        {this.state.types.map((type, index)=>(
          <Form.Label htmlFor={type.id} column sm={3}key={index}>
            <div className='radioButtonForm'>
            <Form.Check id={type.id} type={type.type} name={type.name} checked={this.props.type===type.id} onClick={this.handleUserInput}/>
            {type.img && <img src={'/form_icons/'+type.img}/>}
            <p>{type.caption}</p>
            </div>
          </Form.Label>         
        ))}
        <Col sm={12} >
        	<Button ref={(el) => { this.nextButton = el; }} style={!this.props.type ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark' name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
        </Col>
      </Form.Group>  
		) 
	}
}

export default StairType;