import React, { Component } from 'react';
import ContactForm from './forms/ContactForm';
import StairType from './forms/StairType';
import StairKind from './forms/StairKind';
import StairConstruction from './forms/StairConstruction';
import Railings from './forms/Railings';
import RiserType from './forms/RiserType';
import StairFinish from './forms/StairFinish';
import HoleType from './forms/HoleType';
import HoleDimensions from './forms/HoleDimensions';
import OrderSumUp from './forms/OrderSumUp';
import ConfirmationMessageSend from './forms/ConfirmationMessageSend';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';
import './../../stylesheets/forms.css'

class TrappaOrder extends Component {
  constructor () {
	  super();
	  this.state = {
	  	messageId:  false,
	  	progress: 1,
	  	order: {
	  		kind: 'kind0',
	  		type: false,
	  		hole: false,
	  		holeDims: false,
	  		construction: false,
	  		riser: false,
	  		railing: false,
	  		extraRailing: false,
	  		wood: false,
	  		finish: false,
	  		email: false,
	  		message: false,
	  		mobile: false,
	  		name: false,
	  		lastname: false,
	  		accept: false,
	  	}
    }
    this.closeConfirmationView = this.closeConfirmationView.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.jumpTo = this.jumpTo.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }


  closeConfirmationView(object){
  	this.setState({
  		...this.state,
  		...object
  	})
  }
  saveValues(object) {
  	this.setState({
  		order: {
  			...this.state.order,
  			...object,
  		}
  	})
  }

  sendForm() {
    const order = this.state.order;
    const address = {
    	//local: 'http://127.0.0.1:3003/api/laskowskibygg/trappevaluering', 
    	global: 'https://laskowskicreative.no/api/laskowskibygg/trappevaluering'}
    fetch(address.global, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(order)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
    	{messageId: json.messageId, 
    		progress: 'finish', 
	    	order: {
		  		kind: false,
		  		type: false,
		  		hole: false,
		  		holeDims: false,
		  		construction: false,
		  		riser: false,
		  		railing: false,
		  		extraRailing: false,
		  		wood: false,
		  		finish: false,
		  		email: false,
		  		mobile: false,
		  		message: false,
		  		name: false,
		  		lastname: false,
		  		accept: false,
		  	}
		  }
		))  
  }

   scrollToTop = () => {
  	this.stepList.scrollIntoView({ behavior: "smooth" });
	}
  nextStep(object) {
  	this.scrollToTop();
  	this.setState({
			progress: ++this.state.progress,
  	})
  }

  jumpTo(event){
  	const progress = parseInt(event.target.name);
  	if(progress < this.state.progress)
	  	this.setState({
				progress: progress,
	  	})
  }
	render() {
		const progress = this.state.progress;
		const order = this.state.order;
		const headers = [
			'Vi har alltid de laveste prisene!',
			'Alltid den beste kvaliteten!',
			'Vi vil gi det beste tilbudet for deg!',
			]
		console.log(order)
		return (
		<div id='stairs-form' ref={(el) => { this.stepList = el; }}>
			<Col sm={12}>
				<Row className='kontakt-info justify-content-md-center'>
					<Col sm={12}>
					</Col>
					<Col sm={12}lg={{offset:2, span:4}} style={{textAlign:'center', display: this.state.progress > 0 || this.state.progress === 'finish' ? 'none' : 'block' }}>
						{					
							headers.map((header, index)=>(
								<h2 style={{display: this.state.progress > 0 || this.state.progress === 'finish' ? 'none' : 'inherit' }}>{header}</h2>
							))
						}					
					</Col>
					<Col sm={12}lg={4} style={{textAlign:'center', display: this.state.progress > 0 || this.state.progress === 'finish' ? 'none' : 'inherit' }}>
						<h2 style={{visibility: this.state.progress > 0 || this.state.progress === 'finish' ? 'hidden' : 'visible' }} >Om du ønsker en verdivurdering, fyll ut skjemaet under. Basert på skjemaet, vurderer vi prisen din.</h2> 
					</Col>
				</Row>			
			</Col>
			<Col sm={12} style={{margin: '70px 0 80px 0'}}>
				<Row style={{borderBottom: '1px solid #000', margin: '100px 0', textAlign: 'center'}} >
					<Col  sm={{span: 1}}>
					</Col>
					<Col sm='auto'>
					<a name='0' className={progress === 0 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 0 || progress==='finish'} onClick={this.jumpTo}>TRAPPETYPE</a>
					</Col>
					<Col sm='auto'>
					<a name='1' className={progress === 1 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 1 || progress==='finish'} onClick={this.jumpTo}>TRAPPEMODELLER</a>
					</Col>
					<Col sm='auto'>
					<a name='2' className={progress === 2 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 2 || progress==='finish'} onClick={this.jumpTo}>HULLTYPE</a>
					</Col>
					<Col sm='auto'>
					<a name='3' className={progress === 3 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 3 || progress==='finish'} onClick={this.jumpTo}>HULDIMENSJONER</a>
					</Col>
					<Col sm='auto'>
					<a name='4' className={progress === 4 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 4 || progress==='finish'} onClick={this.jumpTo}>KONSTRUKSJON</a>
					</Col>
					<Col sm='auto'>
					<a name='5' className={progress === 5 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 5 || progress==='finish'} onClick={this.jumpTo}>STUSSTRINN</a>
					</Col>
					<Col sm='auto'>
					<a name='6' className={progress === 6 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 6 || progress==='finish'} onClick={this.jumpTo}>REKKVERK</a>
					</Col>
					<Col sm='auto'>
					<a name='7' className={progress === 7 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 7 || progress==='finish'} onClick={this.jumpTo}>TRAPMATERIALER</a>
					</Col>
					<Col sm='auto'>
						<a name='8' className={progress === 8 ? 'formPositionActive' : 'formPositionUnactive'} hidden={progress < 8 || progress==='finish'} onClick={this.jumpTo}>SAMMENDRAG</a>
					</Col>
					<Col sm={{span: 1}}>
					</Col>
				</Row>
			</Col>
			<Col sm={{offset: 2, span: 8}}>
				<Form style={{textAlign: 'center'}}>
					{progress === 0 && <StairKind kind={order.kind} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 1 && <StairType type={order.type} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 2 && <HoleType hole={order.hole} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 3 && <HoleDimensions hole={order.hole} holeDims={order.holeDims} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 4 && <StairConstruction construction={order.construction} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 5 && <RiserType riser={order.riser} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 6 && <Railings railing={order.railing} extraRailing={order.extraRailing} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 7 && <StairFinish finish={order.finish} wood={order.wood} nextStep={this.nextStep} saveValues={this.saveValues}/>}
					{progress === 8 && <OrderSumUp sendForm={this.sendForm} order={this.state.order}/>}
					{progress === 8 && <ContactForm accept={order.accept} name={order.name} lastname={order.lastname} message={order.message} email={order.email} mobile={order.mobile} sendForm={this.sendForm} saveValues={this.saveValues} progress={this.state.progress}/>}
					{progress === 'finish' && <ConfirmationMessageSend closeConfirmationView={this.closeConfirmationView} messageId={this.state.messageId}/>}
				</Form>
			</Col>
		</div>
		) 
	}
}

export default TrappaOrder;