import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';

import './../../../stylesheets/forms.css'

class RiserType extends Component {
  constructor () {
    super();
    this.state = {
      risers: [
        {
          caption: 'Uten stusstrinn',
          img: 'riser_0.svg',
          id: 'riser0',
          name: 'riser',
          type: 'radio',
        },
        {
          caption: 'Med stusstrinn',
          img: 'riser_1.svg',
          id: 'riser1',
          name: 'riser',
          type: 'radio',
        },
      ]
      }
    this.handleUserInput = this.handleUserInput.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }
  scrollToBottom = () => {
  	this.nextButton.scrollIntoView({ behavior: "smooth" });
}
  handleUserInput(event){
  	this.scrollToBottom();
  	const id = event.target.id,
  		name = event.target.name,
  		forwardObject = {[name]: id};
  	this.props.saveValues(forwardObject);
  }
	render() {
		return (
        <Form.Group as={Row} className="justify-content-sm-center">
          {this.state.risers.map((riser, index)=>(
            <Form.Label column sm={4} htmlFor={riser.id} key={index}>
              <div className='radioButtonForm'>
              <Form.Check id={riser.id} type={riser.type} name={riser.name} checked={this.props.riser===riser.id} onClick={this.handleUserInput}/>
              {riser.img && <img src={'/form_icons/'+riser.img}/>}
              <p>{riser.caption}</p>
              </div>
            </Form.Label>        
          ))}
          <Col sm={12}>
          	<Button ref={(el) => { this.nextButton = el; }} style={!this.props.riser ? {borderRadius: 0, visibility: 'hidden'} : {borderRadius: 0, visibility: 'visible'}} as={Col} variant='outline-dark'  name='next' id='next' onClick={this.props.nextStep}>Neste</Button>
          </Col>
        </Form.Group> 
		) 
	}
}

export default RiserType;